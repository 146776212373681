import { backendAPI } from '@/services/base_api';

export const all = (data = {}) => {
  return backendAPI('GET', 'project/', data);
};

export const createProject = (data = {}) => {
  return backendAPI('POST', 'project/', data);
};

export const deleteProject = (projectId: string | number) => {
  return backendAPI('DELETE', 'project/' + projectId);
};
