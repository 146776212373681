
import { computed, defineComponent, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { isPresent } from '@/utils/lang';
import { switchProject } from '@/helpers/router';
import { Project } from '@/interface/project';

export default defineComponent({
  name: 'top-nav',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const projects: ComputedRef<Project[]> = computed(() => {
      return store.getters['project/projects'];
    });

    const currentProject: ComputedRef<Project> = computed(() => {
      return store.getters['project/currentProject'];
    });

    const hasProject: ComputedRef<boolean> = computed(() => {
      return isPresent(currentProject.value);
    });

    const changeProject = (project: Project): void => {
      if (project.name !== currentProject.value.name) {
        store.dispatch('loading/showLoading');
        setTimeout(() => {
          store.dispatch('project/setCurrentProject', project);
          store.dispatch('loading/hideLoading');

          switchProject(route, router, { projectId: currentProject.value.id }, {});
        }, 500);
      }
    };

    const createProject = (): void => {
      if (router.currentRoute.value.path !== '/projects/new') router.push({ name: 'CreateProject' });
    };

    const logoutUser = (): void => {
      store.dispatch('auth/logout');
      router.push({ name: 'Login' });
    };

    return {
      // Variables

      // Computed
      hasProject,
      projects,
      currentProject,

      // Methods
      changeProject,
      logoutUser,
      createProject,
    };
  },
});
