import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import commonMixin from './mixins/commonMixin';

//import Rx from 'rxjs/Rx';
//import VueRx from 'vue-rx';
import 'mapbox-gl/dist/mapbox-gl.css';
import VueClipboard from 'vue-clipboard2';

// Libraries
import VueLogger from 'vuejs3-logger';
import { options } from '@/utils/logger';

// Style Sheets
import 'nprogress/nprogress.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/stylesheets/common.css';
import '@/assets/stylesheets/animate.css';
import '@/assets/font-icons/css/fontello.css';
import '@/assets/stylesheets/app.scss';

// Boostrap
import 'bootstrap';

import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });

// https://stackoverflow.com/a/65455343/1709287

export const app = createApp(App).use(store).use(router).use(VueClipboard).use(VueLogger, options);

app.mixin(commonMixin);
app.mount('#app');
