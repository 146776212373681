import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

export const isPresent = (value: any) => {
  return !isBlank(value);
};

export const isBlank = (value: any) => {
  if (isNumber(value) || value == true) {
    return false;
  }

  return isEmpty(value);
};
