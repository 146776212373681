
import { computed, defineComponent, ComputedRef } from 'vue';
import { HalfCircleSpinner } from 'epic-spinners';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import first from 'lodash/first';
import TopNav from '@/components/TopNav.vue';
import { isBlank } from '@/utils/lang';
import { switchProject } from '@/helpers/router';
import { Project } from '@/interface/project';

export default defineComponent({
  name: 'App',
  components: {
    TopNav,
    HalfCircleSpinner,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isSignedIn: ComputedRef<boolean> = computed(() => {
      return store.getters['auth/isSignedIn'];
    });

    const isShowLoading: ComputedRef<boolean> = computed(() => {
      return store.getters['loading/isShowLoading'];
    });

    store
      .dispatch('auth/restoreSession')
      .then(() => {
        store.dispatch('auth/verifySession').then(() => {
          if (!isSignedIn.value) return router.push({ name: 'Login' });

          store.dispatch('project/fetchProjects').then((projects: Project[]) => {
            store.dispatch('loading/hideLoading');
            if (isBlank(projects)) return router.push({ name: 'CreateProject' });

            const currentProject = store.getters['project/findProjectId'](route.params.projectId) || first(projects);
            store.dispatch('project/setCurrentProject', currentProject);

            const listRoutes = ['/login', '/signup', '/forgot-password', '/reset-password'];
            if (listRoutes.includes(router.currentRoute.value.path)) {
              router.push({ name: 'Path', params: { projectId: currentProject.id } });
              return;
            }

            if (currentProject.id != route.params.projectId) {
              switchProject(route, router, { projectId: currentProject.id }, {});
              return;
            }
          });
        });
      })
      .catch(() => {
        setTimeout(() => {
          store.dispatch('loading/hideLoading');
          const listRoutes = ['/signup', '/forgot-password', '/reset-password'];
          if (!listRoutes.includes(router.currentRoute.value.path)) {
            router.push({ name: 'Login' });
          }
        }, 100);
      });

    return {
      //Variables

      //Computed
      isSignedIn,
      isShowLoading,
    };
  },
});
