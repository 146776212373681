import { User } from '@/interface/user';

const KEYS = {
  USER: 'localUser',
};

//  STORE LOCAL DATA
export const storeLocalUser = (user: User) => {
  return setItem(KEYS.USER, user);
};

// LOAD FROM LOCAL DATA
export const loadLocalUser = () => {
  return getItem(KEYS.USER);
};

//  CLEAR LOCAL DATA
export const clearLocalUser = () => {
  return removeItem(KEYS.USER);
};

// PRIVATE
const getItem = (key: string) => {
  try {
    const dataString: string | null = localStorage.getItem(key);

    return JSON.parse(dataString || '{}') || {};
  } catch {
    return {};
  }
};

const setItem = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
