
import { computed, defineComponent, PropType, ComputedRef } from 'vue';
import round from 'lodash/round';
import { first, last } from 'lodash';
import moment from 'moment';
import { LatLng } from '@/interface/latLng';

export default defineComponent({
  name: 'Metrics',
  props: {
    latLngs: {
      type: Array as PropType<Array<LatLng>>,
      required: true,
    },
  },
  setup(props) {
    const distance: ComputedRef<number> = computed(() => {
      let distance = 0;
      let prevLatLng = props.latLngs[0];
      for (let index = 1, len = props.latLngs.length; index < len; index++) {
        distance += getDistanceFromLatLngInKm(prevLatLng, props.latLngs[index]);
        prevLatLng = props.latLngs[index];
      }
      // distance convert in meter
      return round(distance * 1000);
    });

    const time: ComputedRef<number> = computed(() => {
      const datetimeFirstLatLng = moment(first(props.latLngs)?.time);
      const datetimeLastLatLng = moment(last(props.latLngs)?.time);
      const diffMillis = datetimeLastLatLng.diff(datetimeFirstLatLng);
      // convert in hour
      return round(diffMillis / 3600000, 2);
    });

    const paceKmPerHour: ComputedRef<number> = computed(() => {
      if (time.value == 0) return 0;

      return round(distance.value / (time.value * 1000), 2);
    });

    const paceMinPerKm: ComputedRef<number> = computed(() => {
      if (distance.value == 0) return 0;

      return round((time.value * 60 * 1000) / distance.value, 2);
    });

    const aveIntervalDistance: ComputedRef<number> = computed(() => {
      return round(distance.value / props.latLngs.length, 2);
    });

    const aveIntervalTime: ComputedRef<number> = computed(() => {
      return round((time.value * 3600) / props.latLngs.length);
    });

    // https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
    const getDistanceFromLatLngInKm = (firstLatLng: LatLng, secondLatLng: LatLng): number => {
      let R = 6371; // Radius of the earth in km
      let dLat = deg2rad(secondLatLng.latitude - firstLatLng.latitude); // deg2rad below
      let dLon = deg2rad(secondLatLng.longitude - firstLatLng.longitude);
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(firstLatLng.latitude)) *
          Math.cos(deg2rad(secondLatLng.latitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c; // Distance in km
      return d;
    };

    const deg2rad = (deg: number): number => {
      return deg * (Math.PI / 180);
    };

    return {
      //Computed
      distance,
      time,
      paceKmPerHour,
      paceMinPerKm,
      aveIntervalDistance,
      aveIntervalTime,
    };
  },
});
