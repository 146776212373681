<template>
  <div class="row">
    <div class="col-md-6 bg-primary" style="min-height: 500px">Some main content</div>
    <div class="col-md-6">
      <div class="row h-100 flex-column">
        <div class="flex-fill bg-danger">Part 1</div>
        <div class="flex-fill bg-success">Part 2</div>
      </div>
    </div>
  </div>

  <!--
  <div class="main-container">
    <div class="left-container">
      <div class="half-containers"></div>
      <div class="half-containers"></div>
    </div>
    <div class="right-container">Criteria container</div>
  </div>

-->
  <!--
  <div class="home">
    <div class="content">
      <section class="clearfix">
        <h2 id="title">LatLng List</h2>
      </section>
    </div>
  </div>
  -->
</template>

<script>
export default {
  name: 'LayoutTest',

  computed: {},

  data() {
    return {
      latLngs: [],

      offset: 0,
      limit: 50,
      totalCount: 100,

      pathId: null,
    };
  },

  methods: {},

  created() {},

  mounted() {},
};
</script>

<style scoped>
/*
html,
body {
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
}
body {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin-top: 2em;
}

div {
  box-sizing: border-box;
}
*/
.home {
  width: 100%;
  height: 100vh;
  background-color: #f3f3f3;
}

.content {
  width: 84%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

/* https://stackoverflow.com/a/46123688/1709287 */
.main-container {
  height: 100vh;
  width: 100%;
  display: flex;
}

.right-container {
  flex: 1 1 20%;
}

.left-container {
  flex: 1 1 80%;
  display: flex;
  flex-direction: column;
}

.half-containers {
  flex: 1;
  overflow: auto;
}

/* https://qiita.com/orangain/items/6268b6528ab33b27f8f2 */
th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
}

/*
.main-container {
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  border: 1px solid red;
}
.right-container {
  flex: 0 1 20%;
  min-height: 100%;
  max-width: 20%;
  border: 1px solid red;
  background: #0bda51;
  padding: 0.5em;
}
.left-container {
  flex: 0 1 80%;
  display: flex;
  height: 100%;
  max-width: 80%;
  flex-wrap: wrap;
  align-items: flex-start;
}
.half-containers {
  flex: 0 0 100%;
  max-height: 50%;
  min-height: 50%;
  max-width: 100%;
  background: #0bda51;
  border: 1px solid red;
  padding: 0.5em;
  overflow: hidden;
}
*/

#title {
  float: left;
  margin: 63px 0 0 0;
  color: #5c5c5c;
  font-weight: normal;
  font-size: 30px;
}

#createOrderButton {
  float: right;
  margin: 53px 0 0 0;
  background-color: #d2ff00;
  color: #5c5c5c;
  border-radius: 11px;
  text-decoration: none;
  padding: 3px 43px 2px 43px;
  font-size: 20px;
  font-weight: normal;

  border-style: solid;
  border-color: Transparent;
  border-width: 2px;
}

#createOrderButton i {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

#createOrderButton:hover {
  border-style: solid;
  border-width: 2px;
  border-color: #c7d931;
}

/* tbodyの外側に枠を引く
  https://stackoverflow.com/a/18990202/1709287
  */
table {
  width: 100%;
  margin-top: 39px;
  height: 100%; /* needed to maximize .center_content height */
  border-collapse: collapse;
}

tbody {
  border: 3px solid #d2d2d2;
  border-collapse: separate;
  border-spacing: 4px;
  background-color: white;
}

/* 各業の下に線を引く
  https://stackoverflow.com/a/10040976/1709287
  */

.col_1 {
  width: 7%;
}

.col_2 {
  width: 9%;
}

.col_3 {
  width: 7%;
}

.col_4 {
  width: 7%;
}

.col_5 {
  width: 7%;
}

.col_6 {
  width: 7%;
}

.col_7 {
  width: 7%;
}

.col_8 {
  width: 7%;
}

.col_9 {
  width: 7%;
}

.col_10 {
  width: 7%;
}

.col_11 {
  width: 7%;
}

.col_12 {
  width: 7%;
}

.col_13 {
  width: 7%;
}

.col_14 {
  width: 7%;
}

tr.latLngRow td:not(.td_link_copy) {
  /* not(.td_link_copy) is used to maximize .center_content height */
  border-bottom: 3px solid #d2d2d2;
  color: #5c5c5c;
  font-weight: lighter;
  font-size: 17px;
  padding: 11px 18px 10px 16px;
}

th {
  text-align: left;
  color: #5c5c5c;
  font-weight: normal;
  font-size: 20px;
}

tr.latLngRow td span:not(.btn_link_copy) {
  /* :not(.btn_link_copy) is used to override the span attributes with btn_link_copy */
  color: #5c5c5c;
  font-weight: lighter;
  font-size: 17px;
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.row:hover {
  background-color: #f2f2f2;
}

.rowStarted {
  background-color: #e8ff7f;
}

.pagination_controller {
  margin-top: 20px;
}

.pagination_arrow_left {
  float: right;
  width: 10px;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 0 0;
}

/* start of 「リンクコピー」 */
.td_link_copy {
  border: 3px solid #d2d2d2;
  height: 100%;
  color: #5c5c5c;
  cursor: pointer;
}

.td_link_copy div {
  justify-content: center;
  cursor: pointer;
}

.center_content {
  height: 100%;
  display: flex;
  align-items: center;
}

.green_border {
  border: 3px solid #e8ff7f;
}

.no_border {
  border: 3px solid Transparent;
}

span.btn_link_copy {
  border: none;
  font-size: 13px;
  display: inline;
  cursor: pointer;
}

.img_copy_icon {
  /* web view */
  width: 21px;
  height: 23px;
  margin-right: 1px;
}

/* end of 「リンクコピー」 */

.pagination_arrow_right {
  float: right;
  width: 10px;
  padding: 10px 10px 10px 10px;
  margin: 0 0 0 20px;
}

.pagination_info {
  float: right;
  margin-right: 20px;
}

.pagination_info span {
  color: #5c5c5c;
  font-size: 18px;
}

#createOrderButtonMobile {
  float: right;
  margin: 18px 0 0 0;
  background-color: #d2ff00;
  color: #5c5c5c;
  border-radius: 11px;
  text-decoration: none;
  padding: 0 12px 0 12px;
  font-size: 20px;
  font-weight: normal;

  border-style: solid;
  border-color: Transparent;
  border-width: 2px;
}

#createOrderButtonMobile i {
  width: 25px;
  height: 25px;
  margin: 0 0 0 0;
}

/*mobile対応(iPad含む)*/
@media (max-width: 768px) {
}

/*mobile対応(iPadを除外)*/
@media (max-width: 767px) {
}
</style>
