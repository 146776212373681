
import { computed, defineComponent, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { isPresent, isBlank } from '@/utils/lang';
import { SignupForm } from '@/interface/form';

export default defineComponent({
  name: 'Signup',
  setup() {
    const store = useStore();
    const router = useRouter();

    // Define a validation schema
    const schema = yup.object({
      /* For custom text error
        email: yup.string().required('must be required).email('must be valid email),
        password: yup.string().required().min(2,'at least 2 characters).max(20,'must be less than 20 characters'),
      */
      email: yup.string().required().email(),
      password: yup.string().required().min(2).max(20),
    });

    // Create a form context with the validation schema
    const { errors, setErrors } = useForm<SignupForm>({
      validationSchema: schema,
    });

    // No need to define rules for fields
    const { value: email } = useField<string>('email');
    const { value: password } = useField<string>('password');

    const isButtonDisabled: ComputedRef<boolean> = computed(() => {
      return isPresent(errors.value) || isBlank(email.value) || isBlank(password.value);
    });

    const signUp = (): void => {
      store.dispatch('loading/showLoading');
      let formData: SignupForm = {
        email: email.value,
        password: password.value,
      };

      store
        .dispatch('user/signup', formData)
        .then(() => {
          store.dispatch('loading/hideLoading');
          router.push({ name: 'Login' }).catch((error) => {
            if (error.name === 'NavigationDuplicated') {
              throw error;
            }
          });
        })
        .catch((error) => {
          if (error.error === 'invalid password') {
            setErrors({
              password: 'invalid password',
            });
          } else {
            setErrors({
              email: 'Signup failed. Email is already being used.',
            });
          }

          store.dispatch('loading/hideLoading');
        });
    };

    return {
      //Variables
      errors,
      email,
      password,

      // computed
      isButtonDisabled,

      //Methods
      signUp,
    };
  },
});
