
import { ref, computed, defineComponent, PropType, reactive, ComputedRef } from 'vue';
import moment from 'moment';
import { isPresent } from '@/utils/lang';
import Map from '@/components/latLng/Map.vue';
import { LatLng, ManageDisplayItem } from '@/interface/latLng';

export default defineComponent({
  name: 'MapLatLng',
  components: {
    Map,
  },
  props: {
    manageDisplayItems: {
      type: Object as PropType<ManageDisplayItem>,
      required: true,
    },
    latLngsData: {
      type: Array as PropType<Array<LatLng>>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      currentLatLng: {} as LatLng,
    });
    const map = ref<InstanceType<typeof Map>>();

    const hasLatLngsData: ComputedRef<boolean> = computed(() => {
      return isPresent(props.latLngsData);
    });

    const latLngCreatedDatetime: ComputedRef<(latLng: LatLng) => string> = computed(() => {
      return (latLng: LatLng) => {
        return moment(latLng.time).format('HH:mm:ss');
      };
    });

    const satelliteNumbers: ComputedRef<(latLng: LatLng) => number | string> = computed(() => {
      return (latLng: LatLng) => {
        if (!latLng.Satellite) return '-';

        return latLng.Satellite.numOfSatellites;
      };
    });

    const satelliteAverageSnr: ComputedRef<(latLng: LatLng) => number | string> = computed(() => {
      return (latLng: LatLng) => {
        if (!latLng.Satellite) return '-';

        return latLng.Satellite.averageSnr;
      };
    });

    const cellularType: ComputedRef<(latLng: LatLng) => string> = computed(() => {
      return (latLng: LatLng) => {
        if (!latLng.Cellular) return '-';

        return latLng.Cellular.type;
      };
    });

    const cellularLevel: ComputedRef<(latLng: LatLng) => number | string> = computed(() => {
      return (latLng: LatLng) => {
        if (!latLng.Cellular) return '-';

        return latLng.Cellular.dBmLevel;
      };
    });

    const isHighlightRow = (latLng: LatLng): boolean => {
      return isPresent(state.currentLatLng) && latLng.id == state.currentLatLng.id;
    };

    const setCurrentLatLng = (latLng: LatLng): void => {
      state.currentLatLng = latLng;
      map.value?.markMarkerSelected(latLng.id);
    };

    return {
      //Variables
      map,

      //Computed
      latLngCreatedDatetime,
      satelliteNumbers,
      satelliteAverageSnr,
      cellularType,
      cellularLevel,
      hasLatLngsData,

      //Methods
      setCurrentLatLng,
      isHighlightRow,
    };
  },
});
