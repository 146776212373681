import * as AuthService from '@/services/auth';
import { isPresent } from '@/utils/lang';
import { loadLocalUser, storeLocalUser, clearLocalUser } from '@/services/localStorage';
import { User } from '@/interface/user';
import { LoginForm } from '@/interface/form';
import { RootStateInterface } from '@/store/';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';

interface State {
  localSessionRestored: boolean;
  currentUser: User;
}

const state: State = {
  localSessionRestored: false,
  currentUser: {} as User,
};

const mutations: MutationTree<State> = {
  SET_CURRENT_USER(state: State, userData: User) {
    state.currentUser = userData;
    storeLocalUser(userData);
  },
  CLEAR_CURRENT_USER(state: State) {
    state.currentUser = {} as User;
    clearLocalUser();
  },
};

const actions: ActionTree<State, RootStateInterface> = {
  setCurrentUser({ commit }, userData: User) {
    commit('SET_CURRENT_USER', userData);
  },
  clearCurrentUser({ commit }) {
    commit('CLEAR_CURRENT_USER');
  },
  login({ dispatch }, formData: LoginForm) {
    return new Promise((resolve, reject) => {
      return AuthService.login(formData)
        .then((userData: any) => {
          if (!userData || !userData.token) throw userData;

          dispatch('setCurrentUser', userData);
          resolve(userData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  restoreSession({ dispatch, state }) {
    if (state.localSessionRestored) return;

    return new Promise((resolve, reject) => {
      const userData = loadLocalUser();
      if (userData && userData.token) {
        dispatch('setCurrentUser', userData);

        // Mark flag user data restored from local storage to state
        state.localSessionRestored = true;

        resolve(userData);
      } else {
        dispatch('clearCurrentUser');
        reject({});
      }
    });
  },
  verifySession({ dispatch }) {
    // Double check user token still valid
    return AuthService.auth()
      .then((userData: any) => {
        if (!userData || !userData.token) throw userData;

        dispatch('setCurrentUser', userData);
      })
      .catch(() => {
        dispatch('clearCurrentUser');
      });
  },
  logout({ dispatch }) {
    dispatch('clearCurrentUser');
  },
};

const getters: GetterTree<State, RootStateInterface> = {
  isSignedIn(state: State) {
    return isPresent(state.currentUser) && isPresent(state.currentUser?.token);
  },
  currentUser(state: State) {
    return state.currentUser;
  },
};

const namespaced: boolean = true;

export const auth: Module<State, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
