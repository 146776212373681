import * as LatLngService from '@/services/latLng';
import { LatLng } from '@/interface/latLng';
import { RootStateInterface } from '@/store/';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';

interface State {
  latLngs: LatLng[];
}

const state = {
  latLngs: [],
};

const mutations: MutationTree<State> = {
  SET_LATLNGS(state: State, latLngs: LatLng[]) {
    state.latLngs = latLngs;
  },
};

const actions: ActionTree<State, RootStateInterface> = {
  setLatLngs({ commit }, latLngs: LatLng[]) {
    commit('SET_LATLNGS', latLngs);
  },
  fetchLatLngs({ dispatch }, pathId: string | number) {
    return LatLngService.all(pathId).then((response: any) => {
      dispatch('setLatLngs', response.data);
      return response.data;
    });
  },
};

const getters: GetterTree<State, RootStateInterface> = {
  latLngs: (state: State) => {
    return state.latLngs;
  },
};

const namespaced: boolean = true;

export const latLng: Module<State, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
