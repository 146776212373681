
import { computed, onMounted, defineComponent, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import first from 'lodash/first';
import { isPresent, isBlank } from '@/utils/lang';
import { Project } from '@/interface/project';
import { LoginForm } from '@/interface/form';

export default defineComponent({
  name: 'Login',
  setup() {
    const store = useStore();
    const router = useRouter();

    // Define a validation schema
    const schema = yup.object({
      /* For custom text error
        email: yup.string().required('must be required).email('must be valid email),
        password: yup.string().required().min(2,'at least 2 characters).max(20,'must be less than 20 characters'),
      */
      email: yup.string().required().email(),
      password: yup.string().required().min(2).max(20),
    });

    // Create a form context with the validation schema
    const { errors, setErrors } = useForm<LoginForm>({
      validationSchema: schema,
    });

    // No need to define rules for fields
    const { value: email } = useField<string>('email');
    const { value: password } = useField<string>('password');

    const isButtonDisabled: ComputedRef<boolean> = computed(() => {
      return isPresent(errors.value) || isBlank(email.value) || isBlank(password.value);
    });

    const doLogin = (): void => {
      store.dispatch('loading/showLoading');
      let formData: LoginForm = {
        email: email.value,
        password: password.value,
      };
      store
        .dispatch('auth/login', formData)
        .then(() => {
          store.dispatch('project/fetchProjects').then((projects: Project[]) => {
            store.dispatch('loading/hideLoading');
            if (isPresent(projects)) {
              store.dispatch('project/setCurrentProject', first(projects));
              router.push({ name: 'Path', params: { projectId: first(projects)?.id } });
            } else {
              router.push({ name: 'CreateProject' });
            }
          });
        })
        .catch((error) => {
          setErrors({
            email: 'Login failed. Please try again.',
          });
          // TODO apply suspense vue3
          store.dispatch('loading/hideLoading');
        });
    };

    onMounted(() => {
      email.value = 'mizutori@hoge.com';
      password.value = 'foobar';
    });

    return {
      //Variables
      errors,
      email,
      password,

      //Computed
      isButtonDisabled,

      //Methods
      doLogin,
    };
  },
});
