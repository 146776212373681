import axios from 'axios';
import store from '@/store';
import qs from 'qs';
import NProgress from 'nprogress';
import { isBlank } from '@/utils/lang';
import { AxiosSetting, HeaderAxiosSetting } from '@/interface/axios';

// TODO need to convert type :any to specific type
axios.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  function (error) {
    NProgress.done();
    return Promise.reject(error);
  }
);

export const backendAPI = (method: string, path: string, data = {}, headers = {}) => {
  return new Promise((resolve, reject) => {
    requester(method, path, data, headers)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          resolve(resp);
        }
      })
      .catch((error) => {
        reject(axiosErrorParse(error));
      });
  });
};

export const backendDownloadFileAPI = (method: string, path: string, data = {}, headers = {}) => {
  headers = {
    Accept: 'application/octet-stream',
    ...attachBearToken(headers),
  };

  const axiosDefaultSetting: any = defaultAxiosDefaultSetting(method, path, data, headers);
  axiosDefaultSetting.responseType = 'arraybuffer';
  return axios(axiosDefaultSetting);
};

export const axiosErrorParse = (error: any) => {
  return error.response.data;
};

// PRIVATE
const requester = (method: string, path: string, data = {}, headers = {}) => {
  // Attach tokens to headers
  headers = attachAppKey(headers);
  headers = attachBearToken(headers);

  const axiosDefaultSetting: any = defaultAxiosDefaultSetting(method, path, data, headers);

  return axios(axiosDefaultSetting);
};

const attachAppKey = (headers: HeaderAxiosSetting) => {
  return {
    ...headers,
    'App-key': process.env.VUE_APP_KEY,
  };
};

const attachBearToken = (headers: HeaderAxiosSetting) => {
  const userData = store.getters['auth/currentUser'] || {};
  const userToken = userData.token;
  if (isBlank(userToken)) return headers;
  if (headers.attachUserToken === false) return headers;

  return {
    ...headers,
    'Api-Token': userToken,
  };
};

const defaultAxiosDefaultSetting = (method: string, path: string, data = {}, headers = {}) => {
  const axiosSetting: AxiosSetting = {
    method: method,
    url: process.env.VUE_APP_BACKEND_END_POINT + path,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  // Set request parameter or body
  if (method.toLowerCase() == 'get') {
    axiosSetting.params = data;
    axiosSetting.paramsSerializer = (params: any) => {
      return qs.stringify(params);
    };
  } else {
    axiosSetting.data = data;
  }

  return axiosSetting;
};
