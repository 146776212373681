import { backendAPI } from '@/services/base_api';
import { SignupForm } from '@/interface/form';

export const signup = (formData: SignupForm) => {
  return backendAPI('POST', 'user/', formData);
};

export const forgotPassword = (formData: { email: string }) => {
  return backendAPI('POST', 'user/forgot_password/', formData);
};

export const resetPassword = (formData: { password: string; resetToken: string }) => {
  return backendAPI('POST', 'user/reset_password/', formData);
};
