
import { computed, defineComponent, ComputedRef, PropType } from 'vue';
import { Path } from '@/interface/path';

export default defineComponent({
  name: 'CriteriaAndroid',
  props: {
    currentPath: {
      type: Object as PropType<Path>,
      required: true,
    },
  },
  setup() {
    const pathCriteriaId: ComputedRef<(path: Path) => number | string> = computed(() => {
      return (path: Path) => {
        if (path.os) {
          if (path.os == 'android' && path.CriteriaAndroid) {
            return path.CriteriaAndroid.id;
          } else if (path.os == 'ios' && path.CriteriaApple) {
            return path.CriteriaApple.id;
          }
        }
        return '-';
      };
    });
    return {
      pathCriteriaId,
    };
  },
});
