
import { ref, reactive, computed, onMounted, provide, onUnmounted, defineComponent, ComputedRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { first, filter, map, values, keyBy, merge, cloneDeep } from 'lodash';
import CriteriaAndroid from '@/components/CriteriaAndroid.vue';
import CriteriaApple from '@/components/CriteriaApple.vue';
import Filter from '@/components/latLng/Filter.vue';
import Metrics from '@/components/latLng/Metrics.vue';
import DataLatLng from '@/components/latLng/DataLatLng.vue';
import MapLatLng from '@/components/latLng/MapLatLng.vue';
import { isPresent, isBlank } from '@/utils/lang';
import { Path } from '@/interface/path';
import { User } from '@/interface/user';
import { Project } from '@/interface/project';
import { Filter as FilterType, LatLng } from '@/interface/latLng';

export default defineComponent({
  name: 'LatLng',
  components: {
    CriteriaAndroid,
    CriteriaApple,
    Filter,
    Metrics,
    DataLatLng,
    MapLatLng,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const currentPath = ref<Path | null>(null);

    const filterObject: FilterType = reactive({
      ageOn: true,
      ageValue: 2000,
      hAccOn: true,
      hAccValue: 40,
      vAccOn: true,
      vAccValue: 40,
    });
    provide('filter', filterObject);

    const manageDisplayItems = ref({
      time: {
        name: 'Time',
        isSelected: true,
      },
      lat: {
        name: 'Lat',
        isSelected: true,
      },
      lng: {
        name: 'Lng',
        isSelected: true,
      },
      HAcc: {
        name: 'HAcc',
        isSelected: true,
      },
      VAcc: {
        name: 'VAcc',
        isSelected: true,
      },
      alt: {
        name: 'Alt',
        isSelected: true,
      },
      bat: {
        name: 'Bat',
        isSelected: true,
      },
      age: {
        name: 'Age',
        isSelected: true,
      },
      latLngNum: {
        name: 'Num',
        isSelected: true,
      },
      satelliteNum: {
        name: 'Num',
        isSelected: true,
      },
      snr: {
        name: 'Snr',
        isSelected: true,
      },
      type: {
        name: 'Type',
        isSelected: true,
      },
      level: {
        name: 'Level',
        isSelected: true,
      },
    });

    const currentProject: ComputedRef<Project> = computed(() => {
      return store.getters['project/currentProject'];
    });

    const hasProject: ComputedRef<boolean> = computed(() => {
      return isPresent(currentProject.value);
    });

    const latLngs: ComputedRef<LatLng[]> = computed(() => {
      return store.getters['latLng/latLngs'];
    });

    const haveLatLngs: ComputedRef<boolean> = computed(() => {
      return isPresent(latLngs.value);
    });

    const latLngsData: ComputedRef<LatLng[]> = computed(() => {
      let results: LatLng[] = cloneDeep(latLngs.value);
      const listLatLngs: LatLng[] = cloneDeep(latLngs.value);
      if (!filterObject.ageOn && !filterObject.hAccOn && !filterObject.vAccOn) return results;

      if (filterObject.ageOn) {
        results = filter(results, (result) => result.age > filterObject.ageValue);
      }
      if (filterObject.hAccOn) {
        results = filter(results, (result) => result.hAccuracy > filterObject.hAccValue);
      }
      if (filterObject.vAccOn) {
        results = filter(results, (result) => result.vAccuracy > filterObject.vAccValue);
      }

      results = map(results, (result) => {
        return {
          ...result,
          isFilter: true,
        };
      });
      // https://stackoverflow.com/questions/35091975/how-to-use-lodash-to-merge-two-collections-based-on-a-key
      const merged = merge(keyBy(listLatLngs, 'id'), keyBy(results, 'id'));
      return values(merged);
    });

    const hasCurrentPath: ComputedRef<boolean> = computed(() => {
      return isPresent(currentPath.value);
    });

    const user: ComputedRef<User> = computed(() => {
      return store.getters['auth/currentUser'];
    });

    const selectDisplayItems = (item: { name: string; isSelected: boolean }) => {
      item.isSelected = !item.isSelected;
    };

    onMounted(() => {
      if (isBlank(user.value)) return router.push({ name: 'Login' });

      const currentProjectId =
        store.getters['project/currentProject'].id || (route.params.projectId as string | number);
      if (isBlank(currentProjectId)) return router.push({ name: 'Path' });

      store
        .dispatch('path/fetchPaths', currentProjectId)
        .then((paths: Path[]) => {
          store.dispatch('loading/showLoading');
          currentPath.value = store.getters['path/findPathId'](route.params.pathId) || first(paths);
          if (isBlank(currentPath.value)) {
            store.dispatch('loading/hideLoading');
            let params = { projectId: currentProject.value.id };
            params = merge(route.params, params);
            router.push({ name: 'Path', params: params });
            return;
          }

          if (currentPath.value?.id != (route.params.pathId as string | number)) {
            store.dispatch('loading/hideLoading');
            let params = { pathId: currentPath.value?.id };
            params = merge(route.params, params);
            router.push({ name: 'LatLng', params: params });
            return;
          }

          store.dispatch('latLng/fetchLatLngs', currentPath.value.id).then(() => {
            store.dispatch('loading/hideLoading');
          });
        })
        .catch((error) => {
          store.dispatch('loading/hideLoading');
          console.log(error);
        });
    });

    onUnmounted(() => {
      store.dispatch('latLng/setLatLngs', []);
    });
    return {
      //Variables
      filter,
      currentPath,
      manageDisplayItems,

      //Computed
      currentProject,
      hasProject,
      latLngs,
      haveLatLngs,
      latLngsData,
      hasCurrentPath,

      //Methods
      selectDisplayItems,
    };
  },
});
