import { createRouter, createWebHistory } from 'vue-router';
import Path from '@/views/Path.vue';
import LatLng from '@/views/LatLng.vue';
import Home from '@/views/Home.vue';
//import MapDashboard from '@/views/MapDashboard';
import Signup from '@/views/Signup.vue';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import AccountSettings from '@/views/AccountSettings.vue';
import LayoutTest from '@/views/LayoutTest.vue';
import ProjectSettings from '@/views/ProjectSettings.vue';
import CreateProject from '@/views/CreateProject.vue';
import ConnectApp from '@/views/ConnectApp.vue';

const routes = [
  {
    path: '/',
    redirect: { name: 'Path' },
  },
  {
    path: '/layout-test',
    name: 'LayoutTest',
    component: LayoutTest,
    meta: { requiresAuth: true },
  },
  {
    path: '/projects/:projectId?/path',
    name: 'Path',
    component: Path,
    meta: { requiresAuth: true },
  },
  {
    path: '/projects/:projectId/path/:pathId/latLng',
    name: 'LatLng',
    component: LatLng,
    meta: { requiresAuth: true },
  },
  {
    path: '/list',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  /*
  {
    path: '/map',
    name: 'MapDashboard',
    component: MapDashboard,
    meta: { requiresAuth: true },
  },

  */
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/projects/new',
    name: 'CreateProject',
    component: CreateProject,
    meta: { requiresAuth: true },
  },
  {
    path: '/account_settings',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: { requiresAuth: true },
  },
  {
    path: '/projects/:projectId/settings',
    name: 'ProjectSettings',
    component: ProjectSettings,
    meta: { requiresAuth: true },
  },
  {
    path: '/projects/:projectId/connect-app',
    name: 'ConnectApp',
    component: ConnectApp,
    meta: { requiresAuth: true },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Path' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
