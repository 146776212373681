
import { onMounted, watch, defineComponent, PropType } from 'vue';
import JQuery from 'jquery';
import mapboxgl, { Map } from 'mapbox-gl';
import filter from 'lodash/filter';
import { isBlank } from '@/utils/lang';
import { LatLng } from '@/interface/latLng';
import { MakerTypes } from '@/interface/map';

export default defineComponent({
  name: 'Map',
  props: {
    latLngsData: {
      type: Array as PropType<Array<LatLng>>,
      required: true,
    },
  },
  setup(props) {
    let map: Map;
    let markers: mapboxgl.Marker[] = [];
    const markerTypes = {
      default: {
        layerId: 'routeLayer',
        sourceName: 'route',
        lineColor: '#fff',
        markerImage: require('@/assets/images/marker_white.svg'),
        lineOpacity: 1,
      },
      hightLight: {
        layerId: 'highLightRouteLayer',
        sourceName: 'highLightRoute',
        lineColor: '#F7D749',
        markerImage: require('@/assets/images/marker_yellow.svg'),
        lineOpacity: 0.5,
      },
    };

    const initializeMap = (): void => {
      map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mizutori/ckwq0ajcc1brp14mqyeslrbxx',
        center: [139.6955122, 35.6958702],
        zoom: 14,
      });

      map.on('load', () => {
        setMapLayout();
        // https://stackoverflow.com/questions/40557070/style-is-not-done-loading-mapbox-gl-js
        setTimeout(drawListPoint, 1000);
      });
    };

    const fitMap = (): void => {
      // https://stackoverflow.com/a/35715102/1709287
      const bounds = new mapboxgl.LngLatBounds();
      markers.forEach((marker) => {
        bounds.extend(marker.getLngLat().wrap());
      });

      map.fitBounds(bounds, { padding: 100, duration: 0 });
    };

    const setMapLayout = (): void => {
      // 何故かスタイルに設定した言語設定が反映されないので、コードで反映する。
      map.setLayoutProperty('country-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('state-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('settlement-major-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('settlement-minor-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('settlement-subdivision-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('airport-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('poi-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('water-point-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('water-line-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('natural-point-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('natural-line-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('waterway-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('path-pedestrian-label', 'text-field', ['get', 'name_ja']);
      map.setLayoutProperty('road-label-simple', 'text-field', ['get', 'name_ja']);
    };

    const addMarkersAndDrawPathLines = (points: LatLng[], markerConfig: MakerTypes): void => {
      addMarkers(points, markerConfig);
      drawPathLines(points, markerConfig);
    };

    const addMarkers = (points: LatLng[], markerConfig: MakerTypes): void => {
      points.forEach((latLng) => {
        const lngLat: mapboxgl.LngLatLike = [latLng.longitude, latLng.latitude];

        const markerElement = document.createElement('div');
        markerElement.id = 'marker-' + latLng.id;
        markerElement.className = 'marker';

        const markerImage = new Image(20, 20);
        markerImage.src = markerConfig.markerImage;
        markerImage.className = 'marker-image';
        markerElement.appendChild(markerImage);

        const selectedImage = new Image(20, 20);
        selectedImage.src = require('@/assets/images/marker_red.svg');
        selectedImage.className = 'marker-image-selected';
        markerElement.appendChild(selectedImage);

        const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: false }).setHTML(
          `
            <p>id: ${latLng.id}</p>
            <p>age: ${latLng.age}</p>
            <p>hAcc: ${latLng.hAccuracy}</p>
            <p>vAcc: ${latLng.vAccuracy}</p>
            <p>bat: ${latLng.battery}</p>
            <p>numOfSat: ${latLng.numOfSatellites}</p>
          `
        );

        let mapboxPin = new mapboxgl.Marker(markerElement, { offset: [0, -10] })
          .setLngLat(lngLat)
          .setPopup(popup) // sets a popup on this marker
          .addTo(map);

        markers.push(mapboxPin);
      });
    };

    const drawPathLines = (points: LatLng[], markerConfig: MakerTypes): void => {
      const coordinates = points.map((latLng) => [latLng.longitude, latLng.latitude]);

      map.addSource(markerConfig.sourceName, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
        },
      });

      map.addLayer({
        id: markerConfig.layerId,
        type: 'line',
        source: markerConfig.sourceName,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': markerConfig.lineColor,
          'line-width': 4,
          'line-opacity': markerConfig.lineOpacity,
        },
      });
    };

    const markMarkerSelected = (id: string | number): void => {
      // Remove all marker selected
      JQuery('.marker.selected').removeClass('selected');
      JQuery(`#marker-${id}`).addClass('selected');
    };

    const clearMarkers = (): void => {
      if (isBlank(markers)) return;

      markers.forEach((marker) => {
        marker.remove();
      });

      for (const [_key, markerType] of Object.entries(markerTypes)) {
        map.removeLayer(markerType.layerId);
        map.removeSource(markerType.sourceName);
      }
    };

    const drawListPoint = (): void => {
      const hightLightMarketPoints = filter(props.latLngsData, (latLng) => latLng.isFilter);
      const normalMarketPoints = filter(props.latLngsData, (latLng) => !latLng.isFilter);

      addMarkersAndDrawPathLines(normalMarketPoints, markerTypes.default);
      addMarkersAndDrawPathLines(hightLightMarketPoints, markerTypes.hightLight);
      fitMap();
    };

    onMounted(() => {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

      //すぐにMapをロードすると、windowのwidthが狭められているケースで領域全部にマップを描画してくれないので、ロードを遅らせる
      setTimeout(initializeMap, 120);
    });

    watch(
      () => props.latLngsData,
      () => {
        clearMarkers();
        drawListPoint();
      }
    );

    return {
      //Variables

      //Methods
      markMarkerSelected,
    };
  },
});
