
import { Modal } from 'bootstrap';
import { onMounted, defineComponent } from 'vue';

export default defineComponent({
  setup() {
    let myModal: Modal;
    const show = (): void => {
      myModal.show();
    };
    const hide = (): void => {
      myModal.hide();
    };

    onMounted(() => {
      myModal = new Modal(document.getElementById('myModal') as HTMLElement, {
        keyboard: false,
      });
    });

    return {
      //Methods
      show,
      hide,
    };
  },
});
