
import { computed, defineComponent, ComputedRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { isPresent, isBlank } from '@/utils/lang';

type LocationQueryValue = string | null;

export default defineComponent({
  name: 'Reset-password',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // Define a validation schema
    const schema = yup.object({
      /* For custom text error
        email: yup.string().required('must be required).email('must be valid email),
        password: yup.string().required().min(2,'at least 2 characters).max(20,'must be less than 20 characters'),
      */
      password: yup.string().required().min(2).max(20),
      passwordConfirmation: yup
        .string()
        .required()
        .min(2, 'password must be at least 2 characters')
        .max(20, 'password must be at most 20 characters'),
    });
    // Create a form context with the validation schema
    const { errors, setErrors } = useForm<{ password: string; passwordConfirmation: string }>({
      validationSchema: schema,
    });

    // No need to define rules for fields
    const { value: passwordConfirmation } = useField<string>('passwordConfirmation');
    const { value: password } = useField<string>('password');

    const isButtonDisabled: ComputedRef<boolean> = computed(() => {
      return isPresent(errors.value) || passwordConfirmation.value !== password.value || isBlank(password.value);
    });

    const resetPassword = (): void => {
      store.dispatch('loading/showLoading');
      let formData: { password: string; resetToken: LocationQueryValue | LocationQueryValue[] } = {
        password: password.value,
        resetToken: route.query.resetToken,
      };
      store
        .dispatch('user/resetPassword', formData)
        .then(() => {
          store.dispatch('loading/hideLoading');
          router.push({ name: 'Login' }).catch((error) => {
            if (error.name === 'NavigationDuplicated') {
              throw error;
            }
          });
        })
        .catch((error) => {
          if (error.error === 'invalid password') {
            setErrors({
              password: 'invalid password',
            });
          } else {
            setErrors({
              password: 'Reset failed. Please try again.',
            });
          }
          store.dispatch('loading/hideLoading');
        });
    };

    return {
      //Variables
      errors,
      passwordConfirmation,
      password,

      // computed
      isButtonDisabled,

      //Methods
      resetPassword,
    };
  },
});
