import * as UserService from '@/services/user';
import { SignupForm } from '@/interface/form';
import { RootStateInterface } from '@/store/';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';

interface State {}

const state = {};

const mutations: MutationTree<State> = {};

const actions: ActionTree<State, RootStateInterface> = {
  signup(_, formData: SignupForm) {
    return new Promise((resolve, reject) => {
      UserService.signup(formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  forgotPassword(_, formData: { email: string }) {
    return new Promise((resolve, reject) => {
      UserService.forgotPassword(formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword(_, formData: { password: string; resetToken: string }) {
    return new Promise((resolve, reject) => {
      UserService.resetPassword(formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters: GetterTree<State, RootStateInterface> = {};

const namespaced = true;

export const user: Module<State, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
