
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['deleteProject'],
  setup(_, { emit }) {
    const deleteProject = (): void => {
      emit('deleteProject');
    };

    return {
      deleteProject,
    };
  },
});
