import { backendAPI } from '@/services/base_api';
import { loadLocalUser } from '@/services/localStorage';
import { LoginForm } from '@/interface/form';
import { User } from '@/interface/user';

export const login = (formData: LoginForm) => {
  return new Promise((resolve, reject) => {
    return backendAPI('POST', 'session', formData)
      .then((resp: any) => {
        const data = resp.status == 201 ? resp.data : {};
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const auth = () => {
  return new Promise((resolve, reject) => {
    const userData: User = loadLocalUser();
    if (!userData || !userData.token) reject({});

    return backendAPI('GET', 'session')
      .then((resp: any) => {
        const data = resp.status == 200 ? resp.data : {};
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
