import * as ProjectService from '@/services/project';
import first from 'lodash/first';
import find from 'lodash/find';
import { Project } from '@/interface/project';
import { RootStateInterface } from '@/store/';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';

interface State {
  projects: Project[];
  project: Project | {};
}

const state = {
  projects: [],
  project: {},
};

const mutations: MutationTree<State> = {
  SET_PROJECTS(state: State, projects: Project[]) {
    state.projects = projects;
  },
  SET_PROJECT(state: State, project: Project) {
    state.project = project;
  },
  CLEAR_PROJECT(state: State) {
    state.project = {};
  },
  ADD_PROJECT(state: State, project: Project) {
    state.projects.push(project);
  },
};

const actions: ActionTree<State, RootStateInterface> = {
  setCurrentProject({ commit }, project: Project) {
    commit('SET_PROJECT', project);
  },
  setProjects({ commit }, projects: Project[]) {
    commit('SET_PROJECTS', projects);
  },
  clearProject({ commit }) {
    commit('CLEAR_PROJECT');
  },
  addProject({ commit }, project: Project) {
    commit('ADD_PROJECT', project);
  },
  fetchProjects({ dispatch }) {
    return ProjectService.all().then((response: any) => {
      dispatch('setProjects', response.data);
      return response.data;
    });
  },
  createProject({ dispatch }, projectName: string) {
    return ProjectService.createProject(projectName).then((response: any) => {
      dispatch('setCurrentProject', response.data.project);
      dispatch('addProject', response.data.project);
      return response.data.project;
    });
  },
  deleteProject({ dispatch, getters }, projectId: string | number) {
    return ProjectService.deleteProject(projectId)
      .then(() => {
        return dispatch('fetchProjects').then(() => {
          const projects = getters.projects;
          if (projects.length > 0) {
            dispatch('setCurrentProject', first(projects));
          } else {
            dispatch('clearProject');
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

const getters: GetterTree<State, RootStateInterface> = {
  projects: (state: State) => {
    return state.projects;
  },
  currentProject: (state: State) => {
    return state.project;
  },
  findProjectId: (state: State) => (projectId: string | number) => {
    return find(state.projects, (project) => project.id == projectId);
  },
};

const namespaced: boolean = true;

export const project: Module<State, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
