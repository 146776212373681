
import { ref, computed, defineComponent, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import DisplayCodeProjectSetting from '@/components/DisplayCodeProjectSetting.vue';
import { Project } from '@/interface/project';

export default defineComponent({
  components: {
    DisplayCodeProjectSetting,
  },
  setup() {
    const store = useStore();

    const clipboard = ref<string>('Copy to clipboard');

    const project: ComputedRef<Project> = computed(() => {
      return store.getters['project/currentProject'];
    });

    const copyToClipboard = (): void => {
      clipboard.value = 'Copied: ' + project.value.apiKey;
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(clipboard.value);
    };

    const resetClipboard = (): void => {
      clipboard.value = 'Copy to clipboard';
    };

    return {
      //Variables
      clipboard,

      //Computed
      project,

      //Methods
      copyToClipboard,
      resetClipboard,
    };
  },
});
