import { createStore, StoreOptions } from 'vuex';

import { auth } from '@/store/modules/auth';
import { latLng } from '@/store/modules/latLng';
import { path } from '@/store/modules/path';
import { project } from '@/store/modules/project';
import { user } from '@/store/modules/user';
import { loading } from '@/store/modules/loading';

export interface RootStateInterface {
  namespaced: string;
}

export const initialRootState: RootStateInterface = {
  namespaced: 'Glatitude',
};

const storeOptions: StoreOptions<RootStateInterface> = {
  state: initialRootState,
  modules: {
    auth,
    latLng,
    path,
    project,
    user,
    loading,
  },
};

export default createStore(storeOptions);
