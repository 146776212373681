import { RootStateInterface } from '@/store/';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';

interface State {
  isLoading: boolean;
}

const state = {
  isLoading: true,
};

const mutations: MutationTree<State> = {
  SHOW_LOADING(state: State) {
    state.isLoading = true;
  },
  HIDE_LOADING(state: State) {
    state.isLoading = false;
  },
};

const actions: ActionTree<State, RootStateInterface> = {
  showLoading({ commit }) {
    commit('SHOW_LOADING');
  },
  hideLoading({ commit }) {
    commit('HIDE_LOADING');
  },
};

const getters: GetterTree<State, RootStateInterface> = {
  isShowLoading: (state: State) => {
    return state.isLoading;
  },
};

const namespaced: boolean = true;

export const loading: Module<State, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
