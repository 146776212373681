
import { computed, inject, ref, defineComponent } from 'vue';
import toNumber from 'lodash/toNumber';
import { Filter } from '@/interface/latLng';

export default defineComponent({
  name: 'Filter',
  setup() {
    const instanceFilter: Filter = inject('filter') as Filter;
    const filter = ref({
      ageValue: instanceFilter.ageValue,
      hAccValue: instanceFilter.hAccValue,
      vAccValue: instanceFilter.vAccValue,
    });

    const ageFilterSwitchSrc = computed(() => {
      if (instanceFilter.ageOn) {
        return require('@/assets/images/filter_switch_on@2x.png');
      } else {
        return require('@/assets/images/filter_switch_off@2x.png');
      }
    });

    const hAccFilterSwitchSrc = computed(() => {
      if (instanceFilter.hAccOn) {
        return require('@/assets/images/filter_switch_on@2x.png');
      } else {
        return require('@/assets/images/filter_switch_off@2x.png');
      }
    });

    const vAccFilterSwitchSrc = computed(() => {
      if (instanceFilter.vAccOn) {
        return require('@/assets/images/filter_switch_on@2x.png');
      } else {
        return require('@/assets/images/filter_switch_off@2x.png');
      }
    });

    const ageFilterSwitchClicked = (): void => {
      instanceFilter.ageOn = !instanceFilter.ageOn;
    };

    const hAccFilterSwitchClicked = (): void => {
      instanceFilter.hAccOn = !instanceFilter.hAccOn;
    };

    const vAccFilterSwitchClicked = (): void => {
      instanceFilter.vAccOn = !instanceFilter.vAccOn;
    };

    const ageChanged = (event: Event): void => {
      const newValue = toNumber((event.target as HTMLInputElement).value);
      if (isNaN(newValue)) {
        filter.value.ageValue = instanceFilter.ageValue;
      } else {
        instanceFilter.ageValue = newValue;
        filter.value.ageValue = newValue;
      }
    };

    const hAccChanged = (event: Event): void => {
      const newValue = toNumber((event.target as HTMLInputElement).value);
      if (isNaN(newValue)) {
        filter.value.hAccValue = instanceFilter.hAccValue;
      } else {
        instanceFilter.hAccValue = newValue;
        filter.value.hAccValue = newValue;
      }
    };

    const vAccChanged = (event: Event): void => {
      const newValue = toNumber((event.target as HTMLInputElement).value);
      if (isNaN(newValue)) {
        filter.value.vAccValue = instanceFilter.vAccValue;
      } else {
        instanceFilter.vAccValue = newValue;
        filter.value.vAccValue = newValue;
      }
    };

    return {
      //Variables
      filter,
      instanceFilter,

      //Computed
      ageFilterSwitchSrc,
      hAccFilterSwitchSrc,
      vAccFilterSwitchSrc,

      //Methods
      ageFilterSwitchClicked,
      hAccFilterSwitchClicked,
      vAccFilterSwitchClicked,
      ageChanged,
      hAccChanged,
      vAccChanged,
    };
  },
});
