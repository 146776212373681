
import { computed, defineComponent, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ChangePlanPopup from '@/components/popups/ChangePlanPopup.vue';
import DeleteProjectPopup from '@/components/popups/DeleteProjectPopup.vue';
import { isBlank, isPresent } from '@/utils/lang';
import { Project } from '@/interface/project';

export default defineComponent({
  components: {
    DeleteProjectPopup,
    ChangePlanPopup,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const currentProject: ComputedRef<Project> = computed(() => {
      return store.getters['project/currentProject'];
    });

    const hasProject: ComputedRef<boolean> = computed(() => {
      return isPresent(currentProject.value);
    });

    const deleteProject = (): void => {
      store.dispatch('loading/showLoading');
      let projectId: string | number = store.getters['project/currentProject'].id;
      store
        .dispatch('project/deleteProject', projectId)
        .then(() => {
          store.dispatch('loading/hideLoading');
          if (isBlank(store.getters['project/currentProject'])) {
            router.push({ name: 'CreateProject' });
          }
        })
        .catch(() => {
          store.dispatch('loading/hideLoading');
        });
    };

    const upgradePlan = (): void => {
      console.log('upgradePlan');
    };

    return {
      //Variables

      //Computed
      currentProject,
      hasProject,

      //Methods
      deleteProject,
      upgradePlan,
    };
  },
});
