
import { ref, computed, onMounted, defineComponent, ComputedRef } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import CriteriaAndroid from '@/components/CriteriaAndroid.vue';
import CriteriaApple from '@/components/CriteriaApple.vue';
import { isPresent } from '@/utils/lang';
import { Path } from '@/interface/path';
import { User } from '@/interface/user';
import { Project } from '@/interface/project';

export default defineComponent({
  name: 'Path',
  components: {
    CriteriaAndroid,
    CriteriaApple,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const projectId = ref<number | string>(1);
    const manageDisplayItems = ref({
      id: {
        name: 'Id',
        isSelected: true,
      },
      date: {
        name: 'Date',
        isSelected: true,
      },
      driver: {
        name: 'Driver',
        isSelected: true,
      },
      device: {
        name: 'Device',
        isSelected: true,
      },
      criteriaID: {
        name: 'Criteria ID',
        isSelected: true,
      },
    });
    const currentPath = ref<Path | null>(null);

    const hasCurrentPath: ComputedRef<boolean> = computed(() => {
      return isPresent(currentPath.value);
    });

    const user: ComputedRef<User> = computed(() => {
      return store.getters['auth/currentUser'];
    });

    const isUserFree: ComputedRef<boolean> = computed(() => {
      return isPresent(user.value.Plan) && user.value.Plan.name === 'free';
    });

    const paths: ComputedRef<Path[]> = computed(() => {
      return store.getters['path/paths'];
    });

    const havePaths: ComputedRef<boolean> = computed(() => {
      return isPresent(paths.value);
    });

    const displayCurrentPlan: ComputedRef<string> = computed(() => {
      if (isUserFree.value) return 'free plan';

      return 'premium plan';
    });

    const highlightRow: ComputedRef<(path: Path) => string> = computed(() => {
      return (path: Path) => {
        let cls = '';
        if (isPresent(currentPath.value) && path.id == currentPath.value?.id) {
          cls = 'path-row-highlighted';
        }
        return cls;
      };
    });

    const pathCriteriaId: ComputedRef<(path: Path) => number | string> = computed(() => {
      return (path: Path) => {
        if (path.os) {
          if (path.os == 'android' && path.CriteriaAndroid) {
            return path.CriteriaAndroid.id;
          } else if (path.os == 'ios' && path.CriteriaApple) {
            return path.CriteriaApple.id;
          }
        }
        return '-';
      };
    });

    const currentProject: ComputedRef<Project> = computed(() => {
      return store.getters['project/currentProject'];
    });

    const hasProject: ComputedRef<boolean> = computed(() => {
      return isPresent(currentProject.value);
    });

    const rowClicked = (path: Path) => {
      currentPath.value = path;
    };

    const analyzeButtonClicked = (path: Path) => {
      const params = { projectId: currentProject.value.id, pathId: path.id };
      router.push({ name: 'LatLng', params: params });
    };

    const selectDisplayItems = (item: { name: string; isSelected: boolean }) => {
      item.isSelected = !item.isSelected;
    };

    const fetchPaths = (): void => {
      store.dispatch('path/fetchPaths', projectId.value);
    };

    const deletePath = (): void => {
      store.dispatch('loading/showLoading');
      const params: { projectId: string | number; currentPathId: string | number | undefined } = {
        projectId: projectId.value,
        currentPathId: currentPath.value?.id,
      };
      store.dispatch('path/deletePath', params).finally(() => {
        currentPath.value = null;
        store.dispatch('loading/hideLoading');
      });
    };

    onMounted(async () => {
      projectId.value = route.params.projectId as string | number;
      if (isPresent(user.value)) {
        fetchPaths();
      } else {
        router.push({ name: 'Login' });
      }
    });

    return {
      //Variables
      projectId,
      manageDisplayItems,

      //Computed
      highlightRow,
      pathCriteriaId,
      paths,
      havePaths,
      displayCurrentPlan,
      currentProject,
      hasProject,
      currentPath,
      hasCurrentPath,

      //Methods
      fetchPaths,
      rowClicked,
      analyzeButtonClicked,
      selectDisplayItems,
      deletePath,
    };
  },
});
