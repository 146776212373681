<template>
  <div>
    <section id="leftPanel">
      <h1>アカウント編集</h1>
      <p class="warningMessage" v-if="isWarning">!未入力の項目があります!</p>

      <div id="mailInputArea">
        <h3 class="inputTitle">メールアドレス</h3>
        <input :class="{ warned: isWarning && !email }" type="text" placeholder="" v-model="email" />
      </div>

      <div>
        <button id="editAccountButton" class="button" :class="{ disabled: isButtonDisabled }" @click="editAccount">
          更新
        </button>
      </div>
    </section>

    <div class="loadingBackground" v-if="isLoading">
      <div class="loadingDialog">
        <half-circle-spinner :animation-duration="1000" :size="60" :color="'#ffffff'" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { HalfCircleSpinner } from 'epic-spinners';

export default {
  name: 'AccountSettings',

  components: {
    HalfCircleSpinner,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const email = ref('');
    const originalEmail = ref('');
    const isLoading = ref(false);
    const isWarning = ref(false);

    const isButtonDisabled = computed(() => {
      if (email.value && email.value !== originalEmail.value) return false;

      return true;
    });

    const editAccount = async () => {
      if (isButtonDisabled.value) {
        console.log('button is disabled');

        isWarning.value = true;
        return;
      }

      isWarning.value = false;

      if (email.value && email.value !== originalEmail.value) {
        console.log('current user is null');
        return;
      }

      isLoading.value = true;

      try {
        //Call change email api
        await new Promise((resolve, reject) => {
          setTimeout(() => resolve(true), 500); // resolve
        });
        router.replace({ name: 'Home' });
      } catch (error) {
        console.log('Error updating the account:', error);
        isLoading.value = false;
      }
    };

    onMounted(() => {
      let user = store.getters.user;

      if (!user) {
        email.value = user.email;
        originalEmail.value = email.value;
      }
    });

    return {
      email,
      originalEmail,
      isLoading,
      isWarning,

      isButtonDisabled,

      editAccount,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#leftPane {
  text-align: left;
  margin-left: 7%;
}

#leftPane h1 {
  color: #5c5c5c;
  font-size: 33px;
  font-weight: normal;
  margin-top: 40px;
}

h2 {
  color: #5c5c5c;
  font-size: 25px;
  font-weight: normal;
  margin-top: 40px;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
}

input {
  width: 300px;
  height: 35px;
  margin-top: 0;
}

#mailInputArea input {
  width: 400px;
}

#editAccountButton {
  margin-bottom: 40px;
}

.inputTitle {
  color: #5c5c5c;
  font-size: 20px;
  font-weight: lighter;
  margin-top: 2px;
}

button.disabled {
  background-color: #d2d2d2;
}

button.disabled:hover {
  border-style: solid;
  border-width: 2px;
  border-color: #d2d2d2;
}

/* 未入力の項目の警告用スタイル */
.warned {
  background-color: #f3c99f;
}

.warningMessage {
  color: #ff6b49;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

input {
  width: 340px;
  height: 28px;
  padding: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #d2d2d2;
  box-shadow: 0 0 0 #d2d2d2;
}

input:focus {
  outline: none !important;
  border: 2px solid #d2ff00;
  box-shadow: 0 0 0 #d2ff00;
}

@media (max-width: 400px) {
  #leftPane {
    text-align: center;
    margin: 0 auto 0 auto;
  }

  #leftPane h1 {
    text-align: left;
    color: #5c5c5c;
    font-size: 21px;
    font-weight: bold;
    margin-top: 39px;
    margin-left: 25px;
  }

  /* 未入力の項目の警告用スタイル */
  .warned {
    background-color: #f3c99f;
  }

  .warningMessage {
    color: #ff6b49;
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .inputTitle {
    text-align: left;
    color: #5c5c5c;
    font-size: 15px;
    font-weight: lighter;
    margin-top: 2px;
    margin-left: 25px;
    margin-bottom: 0;
  }

  input {
    width: 86%;
    margin: 0 auto 0 auto;
    height: 28px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #d2d2d2;
    box-shadow: 0 0 0 #d2d2d2;
  }

  input:focus {
    outline: none !important;
    border: 2px solid #d2ff00;
    box-shadow: 0 0 0 #d2ff00;
  }

  #mailInputArea input {
    width: 86%;
  }

  .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    padding: 7px 10px 7px 10px;
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
