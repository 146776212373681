import moment from 'moment';
export default {
  name: 'commonMixin',
  methods: {
    /* Return String 'YYYY/MM/DD HH:mm' from Date Object*/
    toDateTimeString(date: string) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
  },
};
