
import { ref, computed, defineComponent, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { isPresent, isBlank } from '@/utils/lang';
import { Project } from '@/interface/project';

export default defineComponent({
  name: 'create-project',
  setup() {
    const store = useStore();

    const newProject = ref<boolean>(true);

    // Define a validation schema
    const schema = yup.object({
      /* For custom text error
        email: yup.string().required('must be required).email('must be valid email),
        password: yup.string().required().min(2,'at least 2 characters).max(20,'must be less than 20 characters'),
      */
      name: yup
        .string()
        .matches(/^[a-zA-Z0-9/\-/]*$/, 'Allow letters, number and hyphen')
        .required()
        .min(4, 'Project name should be at least 4 characters')
        .max(30, 'Project name should be less than 30 characters'),
    });

    // Create a form context with the validation schema
    const { errors } = useForm<{ name: string }>({
      validationSchema: schema,
    });

    // No need to define rules for fields
    const { value: name } = useField<string>('name');

    const isButtonDisabled: ComputedRef<boolean> = computed(() => {
      return isPresent(errors.value) || isBlank(name.value);
    });

    const currentProject: ComputedRef<Project> = computed(() => {
      return store.getters['project/currentProject'];
    });

    const hasProject: ComputedRef<boolean> = computed(() => {
      return isPresent(currentProject.value);
    });

    const createProject = (): void => {
      const params: { name: string } = { name: name.value };
      store.dispatch('project/createProject', params).then(() => {
        newProject.value = false;
      });
    };

    return {
      // Variables
      name,
      errors,
      newProject,

      // Computed
      isButtonDisabled,
      hasProject,
      currentProject,

      // methods
      createProject,
    };
  },
});
