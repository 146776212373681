import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d8f864f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  class: "loadingBackground"
}
const _hoisted_3 = { class: "loadingDialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_half_circle_spinner = _resolveComponent("half-circle-spinner")!
  const _component_TopNav = _resolveComponent("TopNav")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isShowLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_half_circle_spinner, {
              "animation-duration": 1000,
              size: 60,
              color: '#ffffff'
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isSignedIn)
      ? (_openBlock(), _createBlock(_component_TopNav, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, {
      key: _ctx.$route.fullPath,
      class: _normalizeClass(["content-wrapper", { 'full-height': !_ctx.isSignedIn || _ctx.isShowLoading }])
    }, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, { "enter-active-class": "animated fadeIn" }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}