
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['upgradePlan'],
  setup(_, { emit }) {
    const upgradePlan = (): void => {
      emit('upgradePlan');
    };

    return {
      upgradePlan,
    };
  },
});
