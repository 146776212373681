
import { ref, computed, defineComponent, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { isPresent, isBlank } from '@/utils/lang';
import ShowInfoForgotPassword from '@/components/popups/ShowInfoForgotPassword.vue';

export default defineComponent({
  name: 'forgot-password',
  components: {
    ShowInfoForgotPassword,
  },
  setup() {
    const store = useStore();

    const showInfoForgotPassword = ref<InstanceType<typeof ShowInfoForgotPassword>>();
    // Define a validation schema
    const schema = yup.object({
      /* For custom text error
        email: yup.string().required('must be required).email('must be valid email),
      */
      email: yup.string().required().email(),
    });

    // Create a form context with the validation schema
    const { errors, setErrors, handleReset } = useForm<{ email: string }>({
      validationSchema: schema,
    });

    // No need to define rules for fields
    const { value: email } = useField<string>('email');

    const isButtonDisabled: ComputedRef<boolean> = computed(() => {
      return isPresent(errors.value) || isBlank(email.value);
    });

    const forgotPassword = (): void => {
      store.dispatch('loading/showLoading');
      let formData: { email: string } = {
        email: email.value,
      };

      store
        .dispatch('user/forgotPassword', formData)
        .then(() => {
          showInfoForgotPassword.value?.show();
          store.dispatch('loading/hideLoading');
          handleReset();
        })
        .catch(() => {
          setErrors({
            email: 'Email is not existed.',
          });
          store.dispatch('loading/hideLoading');
        });
    };

    return {
      //Variables
      errors,
      email,
      showInfoForgotPassword,

      //Computed
      isButtonDisabled,

      //Methods
      forgotPassword,
      handleReset,
    };
  },
});
