<template>
  <div class="home">
    <div class="content">
      <section class="clearfix">
        <h2 id="title">プロジェクト一覧</h2>
        <router-link id="createProjectButton" v-bind:to="{ name: 'CreateProject' }"
          ><i class="icon-plus"></i>プロジェクの作成</router-link
        >
      </section>

      <table>
        <colgroup>
          <col v-bind:class="col_1" />
          <col v-bind:class="col_2" />
          <col v-bind:class="col_3" />
          <col v-bind:class="col_4" />
        </colgroup>

        <thead>
          <tr>
            <th>Created</th>
            <th>Name</th>
          </tr>
        </thead>

        <tbody v-if="orders">
          <tr
            class="orderRow"
            v-bind:class="{
              orderRowStarted: order.data().status === 'started',
              orderRowNotStarted: order.data().status !== 'started',
            }"
            v-for="order in orders"
            v-bind:key="order.id"
            @click="rowClicked(order)">
            <td>
              {{ order.data().client }}
            </td>

            <!-- デイサービスのときは非表示 -->
            <td v-if="company != null && company.type !== 'dayservice'">{{ orderCreatedDatetime(order.data()) }}</td>
            <td v-if="company != null && company.type !== 'dayservice'">{{ order.data().name }}</td>

            <td>
              <div v-if="order.data().destination_address != null">
                {{ order.data().destination_address }}
              </div>
            </td>
            <!--<td>{{drivers[order['id']]}}</td>-->
            <td>
              {{ getDriverName(order.data().driver_id)
              }}<span v-if="order.data().status === 'started'"> ({{ dynamicTitle('delivering') }})</span>
            </td>

            <td class="td_link_copy" @click.stop="copyURL(order)">
              <!-- URLコピーしたアイコン -->
              <div class="center_content green_border" v-if="order.copied == true">
                <span class="btn_link_copy"
                  ><img
                    class="img_copy_icon"
                    src="../assets/images/url_copy_selected.png"
                    alt="url_link" />コピーしました</span
                >
              </div>
              <!-- URLコピーするアイコン -->
              <div class="center_content no_border" v-else>
                <span class="btn_link_copy"
                  ><img class="img_copy_icon" src="../assets/images/url_copy.png" alt="url_link" />リンクをコピー</span
                >
              </div>
            </td>

            <!--
          <td>
            <router-link v-bind:to="{ name: 'OrderDetail', params: { id: order['id'] }}">詳細</router-link>
          </td>
          --></tr>
        </tbody>
        <tfoot>
          <!--
        <td colspan="1" align="left">
          <strong>Total Number:</strong>
        </td>
        <td>{{ orders.length }}</td>
        --></tfoot>
      </table>

      <div class="pagination_controller">
        <img class="pagination_arrow_right" :src="rightArrowSrc" alt="Right Arrow" @click="loadNextOrders" />

        <img class="pagination_arrow_left" :src="leftArrowSrc" alt="Left Arrow" @click="loadPreviousOrders" />

        <div class="pagination_info">
          <span>{{ paginationInfo }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Home',

  computed: {
    paginationInfo() {
      let idx = this.currentPage - 1;
      let from = idx * this.perPage + 1;
      let to = from + this.orders.length - 1;
      return `${from} -  ${to}`;
    },

    leftArrowSrc() {
      if (this.isFirstOrderShown) {
        return require('@/assets/images/arrow_left_inactive@2x.png');
      } else {
        return require('@/assets/images/arrow_left_active@2x.png');
      }
    },

    rightArrowSrc() {
      if (this.isLastOrderShown) {
        return require('@/assets/images/arrow_right_inactive@2x.png');
      } else {
        return require('@/assets/images/arrow_right_active@2x.png');
      }
    },

    trackingDatetime: function () {
      //self = this;
      return function (order) {
        let tracking = order['tracking'];
        if (tracking != null) {
          let date = tracking['datetime'].toDate();
          return moment(date).format('YYYY/MM/DD HH:mm');
        }
        return '';
      };
    },

    orderCreatedDatetime: function () {
      //self = this;
      return function (order) {
        let createdAt = order['createdAt'];
        if (createdAt != null) {
          let date = createdAt.toDate();
          return moment(date).format('YYYY/MM/DD HH:mm');
        }
        return '';
      };
    },

    // returns true if mobile phone with touchscreen
    isTouchScreen: function () {
      let touchDevice = 'ontouchstart' in document.documentElement;
      return touchDevice;
    },
  },

  data() {
    return {
      projects: [],

      perPage: 50,
      currentPage: 1, //最初のページは1 (0ではない)

      isFirstOrderShown: true,
      isLastOrderShown: false,

      company: null,
    };
  },

  methods: {
    async fetchNextOrders() {
      try {
        this.$store
          .dispatch('latLng/fetchLatLngs')
          .then(() => {
            this.$router.push({ name: 'Home' }).catch((error) => {
              if (error.name != 'NavigationDuplicated') {
                throw error;
              }
            });
            this.isLoading = false;
          })
          .catch((error) => {
            this.$log.error('[Login#Form Component]', error.response.data.error);
            this.errorMsg = error.response.data.error;
            this.isLoading = false;
          });

        this.isFirstOrderShown = true;
        this.currentPage = 1;
        this.isLastOrderShown = true;

        this.latLngs = this.state.latLngs;
      } catch (error) {
        console.log('Error while fetching next orders', error);
      }
    },

    async fetchPreviousOrders() {
      //これを参考にした
      //https://stackoverflow.com/a/54075453/1709287
      try {
        this.$store
          .dispatch('latLng/fetchLatLngs')
          .then(() => {
            this.$router.push({ name: 'Home' }).catch((error) => {
              if (error.name != 'NavigationDuplicated') {
                throw error;
              }
            });
            this.isLoading = false;
          })
          .catch((error) => {
            this.$log.error('[Login#Form Component]', error.response.data.error);
            this.errorMsg = error.response.data.error;
            this.isLoading = false;
          });

        this.latLngs = this.state.latLngs;

        this.isFirstOrderShown = true;
        this.currentPage = 1;
        this.isLastOrderShown = true;
      } catch (error) {
        console.log('Error while fetching previous orders', error);
      }
    },

    rowClicked(orderDoc) {
      console.log('order pushed', orderDoc.id);
      this.$router.push({ name: 'OrderDetail', params: { id: orderDoc['id'] } });
    },

    loadNextOrders() {
      //this.updateOrders()
      this.fetchNextOrders();
    },

    loadPreviousOrders() {
      this.fetchPreviousOrders();
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
  },

  mounted() {
    let user = this.$store.getters.user;
    if (!user) {
      this.fetchNextOrders();
    } else {
      this.$router.push('/signin');
    }
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100vh;
  background-color: #f3f3f3;
}

.content {
  width: 84%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

#title {
  float: left;
  margin: 63px 0 0 0;
  color: #5c5c5c;
  font-weight: normal;
  font-size: 30px;
}

#createOrderButton {
  float: right;
  margin: 53px 0 0 0;
  background-color: #d2ff00;
  color: #5c5c5c;
  border-radius: 11px;
  text-decoration: none;
  padding: 3px 43px 2px 43px;
  font-size: 20px;
  font-weight: normal;

  border-style: solid;
  border-color: Transparent;
  border-width: 2px;
}

#createOrderButton i {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

#createOrderButton:hover {
  border-style: solid;
  border-width: 2px;
  border-color: #c7d931;
}

/* tbodyの外側に枠を引く
  https://stackoverflow.com/a/18990202/1709287
  */
table {
  width: 100%;
  margin-top: 39px;
  height: 100%; /* needed to maximize .center_content height */
  border-collapse: collapse;
}

tbody {
  border: 3px solid #d2d2d2;
  border-collapse: separate;
  border-spacing: 4px;
  background-color: white;
}

/* 各業の下に線を引く
  https://stackoverflow.com/a/10040976/1709287
  */

.col_1 {
  width: 11%;
}

.col_2 {
  width: 19%;
}

.col_3 {
  width: 16%;
}

.col_4 {
  width: 42%;
}

.col_5 {
  width: 11%;
}

/* デイサービス用のカラム幅設定*/
.col_1_ds {
  width: 20%;
}

.col_2_ds {
  width: 60%;
}

.col_3_ds {
  width: 20%;
}

.col_4_ds {
  width: 0%;
}

.col_5_ds {
  width: 0%;
}

tr.orderRow td:not(.td_link_copy) {
  /* not(.td_link_copy) is used to maximize .center_content height */
  border-bottom: 3px solid #d2d2d2;
  color: #5c5c5c;
  font-weight: lighter;
  font-size: 17px;
  padding: 11px 18px 10px 16px;
}

th {
  text-align: left;
  color: #5c5c5c;
  font-weight: normal;
  font-size: 20px;
}

tr.orderRow td span:not(.btn_link_copy) {
  /* :not(.btn_link_copy) is used to override the span attributes with btn_link_copy */
  color: #5c5c5c;
  font-weight: lighter;
  font-size: 17px;
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.orderRow:hover {
  background-color: #f2f2f2;
}

.orderRowStarted {
  background-color: #e8ff7f;
}

.pagination_controller {
  margin-top: 20px;
}

.pagination_arrow_left {
  float: right;
  width: 10px;
  padding: 10px 10px 10px 10px;
  margin: 0 20px 0 0;
}

/* start of 「リンクコピー」 */
.td_link_copy {
  border: 3px solid #d2d2d2;
  height: 100%;
  color: #5c5c5c;
  cursor: pointer;
}

.td_link_copy div {
  justify-content: center;
  cursor: pointer;
}

.center_content {
  height: 100%;
  display: flex;
  align-items: center;
}

.green_border {
  border: 3px solid #e8ff7f;
}

.no_border {
  border: 3px solid Transparent;
}

span.btn_link_copy {
  border: none;
  font-size: 13px;
  display: inline;
  cursor: pointer;
}

.img_copy_icon {
  /* web view */
  width: 21px;
  height: 23px;
  margin-right: 1px;
}

/* end of 「リンクコピー」 */

.pagination_arrow_right {
  float: right;
  width: 10px;
  padding: 10px 10px 10px 10px;
  margin: 0 0 0 20px;
}

.pagination_info {
  float: right;
  margin-right: 20px;
}

.pagination_info span {
  color: #5c5c5c;
  font-size: 18px;
}

#createOrderButtonMobile {
  float: right;
  margin: 18px 0 0 0;
  background-color: #d2ff00;
  color: #5c5c5c;
  border-radius: 11px;
  text-decoration: none;
  padding: 0 12px 0 12px;
  font-size: 20px;
  font-weight: normal;

  border-style: solid;
  border-color: Transparent;
  border-width: 2px;
}

#createOrderButtonMobile i {
  width: 25px;
  height: 25px;
  margin: 0 0 0 0;
}

/*mobile対応(iPad含む)*/
@media (max-width: 768px) {
}

/*mobile対応(iPadを除外)*/
@media (max-width: 767px) {
}
</style>
