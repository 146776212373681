import * as PathService from '@/services/path';
import find from 'lodash/find';
import { Path } from '@/interface/path';
import { RootStateInterface } from '@/store/';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';

interface State {
  paths: Path[];
}

const state = {
  paths: [],
};

const mutations: MutationTree<State> = {
  SET_PATHS(state: State, paths: Path[]) {
    state.paths = paths;
  },
};

const actions: ActionTree<State, RootStateInterface> = {
  fetchPaths({ commit }, projectId: string | number) {
    return PathService.all(projectId).then((response: any) => {
      commit('SET_PATHS', response.data);
      return response.data;
    });
  },
  deletePath({ dispatch }, params: any) {
    return PathService.deletePath(params.currentPathId).then(() => {
      return dispatch('fetchPaths', params.projectId);
    });
  },
};

const getters: GetterTree<State, RootStateInterface> = {
  paths: (state: State) => {
    return state.paths;
  },
  findPathId: (state: State) => (pathId: string | number) => {
    return find(state.paths, (path) => path.id == pathId);
  },
};

const namespaced: boolean = true;

export const path: Module<State, RootStateInterface> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
